@charset "utf-8";

body {
	margin: 0;
	background-color: #101010;
	font-family: 'Roboto', sans-serif;
	color: #ffffff;
	height: 100vh;
	font-size: 0;
}

#root {
	height: 100%;
}

.container {
	margin: 0 auto;
	padding: 0 15px;
	max-width: 990px;
	height: 100%;
	display: flex;
	flex-direction: column;
}

a {
	color: #ffffff;
	text-decoration: none;
}

a:hover {
	color: #ff0040;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 30px 0;
}

.logo img {
	height: 30px;	
}

.social a {
	font-size: 20px;
	padding: 5px;
	margin-left: 20px;
}

@media screen and (max-width: 675px) {
	.header {
		flex-direction: column;	
	}

	.logo {
		margin-bottom: 15px;
	}

	.social a {
		margin: 10px;
	}
}

.main { 
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 30px 0;
	font-size: 14px;
}

.footer a {
	margin-left: 15px;	
}

.gallery {
	width: 100%;
	display: grid;
	grid-template-columns: calc(calc(100%/3) - 10px) calc(calc(100%/3) - 10px) calc(calc(100%/3) - 10px);
	grid-gap: 15px;
}

.gallery-entry {
	font-size: 0;
	position: relative;
}

.gallery-entry img {
	width: 100%;
	height: auto;
}

.gallery-entry-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #000000;
	opacity: 0;
	transition: 0.5s ease;
}

.gallery-entry a:hover .gallery-entry-overlay {
	opacity: 0.5;
}

.gallery-entry-title {
	opacity: 0;
	transition: .5s ease;
	text-align: center;
	color: #ffffff;
	font-size: 30px;
	position: absolute;
	left: 12.5%;
	width: 75%;
	top: 50%;
	transform: translate(0%, -50%);
}

.gallery-entry a:hover .gallery-entry-title {
	opacity: 1;
}

@media screen and (max-width: 675px) {
	.gallery {
		grid-template-columns: calc(50% - calc(15px/2)) calc(50% - calc(15px/2));
	}
}





.modal {
	display: flex;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	align-items: center;
	justify-content: space-around;
}

.modal-overlay {
	
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #101010E6;
}

.modal-close {
	color: #ffffff;
	font-size: 20px;
	position: fixed;
	top: 15px;
	right: 15px;
}

.modal-overlay-wrapper {
	z-index: 1;
	position: fixed;
}

.modal-content {
	z-index: 2;
	box-sizing: border-box;
	background-color: #101010;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.modal-content video {
	max-width: 1280px;
	max-height: 720px;
}

.terms {
	width: 100%;
	font-size: 14px;
}
